import React from "react";
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import './components.css';

export const MinziText = React.memo(function MinziText({
    label, id, value, onChange, placeholder, isRequired, icon, isDisabled
}) {
    return <div className="d-flex-center w-100">
        {icon && <span style={{ marginRight: '10px' }}>{icon}</span>}
        <div className="w-100">
            <Field label={label} isRequired={isRequired ?? false} name={id ?? 'test'} >
                {({ fieldProps }) => (
                    <div className="dv-text-input">
                        <input
                            className="mz-text-input"
                            type="text"
                            defaultValue={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            isDisabled={isDisabled} />
                    </div>
                )}
            </Field>
        </div>
    </div>
})

export const MinziTextValue = React.memo(function MinziText({
    label, id, value, onChange, placeholder, isRequired, icon, onEnter
}) {
    return <div className="d-flex-center w-100">
        {icon && <span style={{ marginRight: '10px' }}>{icon}</span>}
        <div className="w-100">
            <Field label={label} isRequired={isRequired ?? false} name={id ?? 'test'} >
                {({ fieldProps }) => (
                    <div className="dv-text-input">
                        <input
                            className="mz-text-input"
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            onKeyPress={e => {
                                if (e.charCode === 13 && onEnter) onEnter()
                            }} />
                    </div>
                )}
            </Field>
        </div>
    </div>
})

export const MinziTimer = React.memo(function MinziText({
    label, id, value, onChange, placeholder, isRequired, icon, onEnter, className
}) {
    return <div className={`d-flex-center w-100 ${className}`}>
        {icon && <span style={{ marginRight: '10px' }}>{icon}</span>}
        <div className={`w-100`}>
            <Field label={label} isRequired={isRequired ?? false} name={id ?? 'test'} >
                {({ fieldProps }) => (
                    <div className="dv-text-input">
                        <input
                            className="mz-text-input"
                            type="time"
                            defaultValue={value}
                            onChange={onChange} />
                    </div>
                )}
            </Field>
        </div>
    </div>
})