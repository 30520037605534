import { Cache } from "../../cache/cache"
import React from "react";
import Task16Icon from '@atlaskit/icon-object/glyph/task/16'
import Subtask16Icon from '@atlaskit/icon-object/glyph/subtask/16'
import Bug16Icon from '@atlaskit/icon-object/glyph/bug/16'
import Story16Icon from '@atlaskit/icon-object/glyph/story/16'
import Epic16Icon from '@atlaskit/icon-object/glyph/epic/16'
import Changes16Icon from '@atlaskit/icon-object/glyph/changes/16'
import Calendar16Icon from '@atlaskit/icon-object/glyph/calendar/16';

export function generateQuoteMapWithNoGroup(data) {
    let quoteMap = {}
    data.forEach(item => {
        quoteMap[`droppable_${item.id}`] = [...item.cards]
    })
    quoteMap['droppable_delete'] = []
    return quoteMap
}

export function generateQuoteMap(data, group) {
    const groupKeys = {}
    const columns = {}
    const quoteMap = {}
    const headerMap = {}

    data.forEach(item => {
        const groupContent = getGroupOptions(item.cards, group)
        Object.keys(groupContent).forEach(key => {
            const content = groupContent[key]

            quoteMap[`droppable_${item.id}_${key}`] = [...content.data]
            quoteMap[`droppable_delete_${key}`] = []

            const groupsCards = []
            data.forEach(item => {
                groupsCards.push(`droppable_${item.id}_${key}`)
                columns[`droppable_${item.id}_${key}`] = { ...item, cards: null }
            })
            groupsCards.push(`droppable_delete_${key}`)
            columns[`droppable_delete_${key}`] = { name: 'ARQUIVAR', workPlanStatus: [{ type: 'arquivado' }, { type: 'nao_exibir' }], type: 'delete' }
            groupKeys[`droppable_${key}`] = groupsCards
            headerMap[`droppable_${key}`] = { ...content.header, accordionOpen: true }
        })
    })

    Object.keys(groupKeys).forEach(key => {
        const c = groupKeys[key]
        c.forEach(key => quoteMap[key] = quoteMap[key] ?? [])
    })

    return { quoteMap, groupKeys, columns, headerMap }
}

export function getColumnKeys(data) {
    const columnsKey = data.map(x => `droppable_${x.id}`)
    columnsKey.push('droppable_delete')
    return columnsKey
}

export function getColumns(data) {
    let columnMap = {}
    data.forEach(item => {
        columnMap[`droppable_${item.id}`] = { ...item, cards: null }
    })
    columnMap['droppable_delete'] = { name: 'ARQUIVAR', workPlanStatus: [{ type: 'arquivado' }, { type: 'nao_exibir' }] }
    return columnMap
}

function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

export function getGroupOptions(cards, group) {

    group = parseInt(group)
    const groupName = `group`
    const options = { group: { data: [], header: {} } }

    if (group === 0 || !group) {
        options.group.data.push(...cards)
        return options
    }

    const groupByField = (field, minID) => {
        let codes = []
        cards.forEach(item => {
            if (item[field] !== null && item[field] !== undefined && item[field] > minID) {
                const key = `${groupName}-${item[field]}`
                options[key] = options[key] ?? { data: [], header: {} }
                options[key].data.push(item)
                codes.push(item[field])
            } else {
                options.group.data.push(item)
            }
        })
        codes = codes.filter(onlyUnique)
        return codes
    }

    // epicos, historias e desvios
    if (group === 1) {
        const codes = groupByField('parentCardID', 0)
        codes.forEach(parentCardID => {
            const card = cards.find(c => c.parentCardID === parentCardID)
            options[`${groupName}-${parentCardID}`].header = { ...card.parent }
        })
    }

    // prioridade
    if (group === 2) {
        cards.forEach(card => {
            card.priorityCode = card.priorityCode ?? 9
        })
        const codes = groupByField('priorityCode', -1)
        const priorities = {
            0: 'Muito Alto',
            1: 'Alto',
            2: 'Médio',
            3: 'Baixo',
            4: 'Muito Baixo',
            9: 'Sem prioridade'
        }
        codes.forEach(priorityCode => {
            options[`${groupName}-${priorityCode}`].header = { name: priorities[priorityCode], priority: priorityCode }
        })
    }

    // responsavel
    if (group === 3) {
        const codes = groupByField('assigenedToID', 0)
        codes.forEach(assigenedToID => {
            const card = cards.find(c => c.assigenedToID === assigenedToID)
            options[`${groupName}-${assigenedToID}`].header = { ...card.assigened }
        })
    }


    // projeto
    if (group === 4) {
        const codes = groupByField('projectID', 0)
        codes.forEach(projectID => {
            const card = cards.find(c => c.projectID === projectID)
            options[`${groupName}-${projectID}`].header = { ...card.project }
        })
    }

    // centro de custo
    if (group === 5) {
        const codes = groupByField('costCenterID', 0)
        codes.forEach(costCenterID => {
            const card = cards.find(c => c.costCenterID === costCenterID)
            options[`${groupName}-${costCenterID}`].header = { ...card.costCenter }
        })
    }

    // tags
    if (group === 6) {
        let codes = []
        const tags = {}
        cards.forEach(item => {
            item.tags = item.tags ?? []
            if (item.tags.length > 0)
                item.tags.forEach(tag => {
                    const tagName = tag.tag.toLowerCase()
                    options[`${groupName}-${tagName}`] = options[`${groupName}-${tagName}`] ?? { data: [], header: {} }
                    options[`${groupName}-${tagName}`].data.push(item)
                    codes.push(tagName)
                    tags[tagName] = { ...tag, tag: tagName }
                })
            else options.group.data.push(item)
        })
        codes = codes.filter(onlyUnique)
        codes.forEach(tag => {
            options[`${groupName}-${tag}`].header = { ...tags[tag] }
        })
    }

    if (options.group.data.length === 0) {
        delete options.group
    }

    return options
}

export function getFilterOptions(groupKeys, quoteMap) {
    const mapItems = {
        tags: {},
        project: {},
        costCenter: {},
        assigened: {}
    }
    Object.keys(groupKeys).forEach(key => {
        const columnKeys = groupKeys[key]
        columnKeys.forEach(key => {
            const cards = quoteMap[key]
            cards.forEach(card => {
                if (card.tags.length > 0) card.tags.forEach(tag => mapItems.tags[tag.tag.toLowerCase()] = tag)
                if (card.project) mapItems.project[card.project.id] = card.project
                if (card.costCenter) mapItems.costCenter[card.costCenter.id] = card.costCenter
                if (card.assigened) mapItems.assigened[card.assigened.id] = card.assigened
            })
        })
    })

    let tags = Object.keys(mapItems.tags).map(tag => mapItems.tags[tag])
    let projects = Object.keys(mapItems.project).map(project => mapItems.project[project])
    let costCenters = Object.keys(mapItems.costCenter).map(costCenter => mapItems.costCenter[costCenter])
    const assigened = Object.keys(mapItems.assigened).map(assigened => mapItems.assigened[assigened])

    tags = [{ id: -1, tag: 'Sem tag', color: '#000' }, ...tags]
    projects = [{ id: -1, name: 'Sem projeto', color: '#000' }, ...projects]
    costCenters = [{ id: -1, name: 'Sem centro de custo' }, ...costCenters]

    return { tags, projects, costCenters, assigened }
}

export function getFilterState() {
    return {
        cardType: {},
        project: {},
        costCenter: {},
        tag: {},
        ...Cache.kanbanFilter().get()
    }
}

export function applyFilter(cards, filter) {
    const { tag, project, costCenter, cardType, search, people } = filter

    const cardTypeFilter = Object.keys(cardType)
    if (cardTypeFilter.length > 0) {
        const cardsFiltered = cards.filter(card => cardTypeFilter.includes(card.cardType))
        cards = cardsFiltered
    }

    const tagsFilter = Object.keys(tag)
    if (tagsFilter.length > 0) {
        const cardsFiltered = cards.filter(card =>
            card.tags.findIndex(tag => tagsFilter.includes(tag.tag)) >= 0 ||
            (card.tags.length === 0 && tagsFilter.includes('Sem tag')))
        cards = cardsFiltered
    }

    const projectFilter = Object.keys(project).map(x => parseInt(x))
    if (projectFilter.length > 0) {
        const cardsFiltered = cards.filter(card => projectFilter.includes(card.projectID) ||
            (card.projectID === null && tagsFilter.includes(-1)))
        cards = cardsFiltered
    }

    const costCenterFilter = Object.keys(costCenter).map(x => parseInt(x))
    if (costCenterFilter.length > 0) {
        const cardsFiltered = cards.filter(card => costCenterFilter.includes(card.costCenterID) ||
            (card.costCenterID === null && costCenterFilter.includes(-1)))
        cards = cardsFiltered
    }

    if (search && search.length > 0) {
        const cardsFiltered = cards.filter(card =>
            card.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            card.cardKey.toLowerCase().indexOf(search.toLowerCase()) >= 0)
        cards = cardsFiltered
    }

    if (people && people.length > 0) {
        const cardsFiltered = cards.filter(card => people.includes(card.assigenedToID))
        cards = cardsFiltered
    }

    return cards
}

export function getCardAllStarted(groupKeys, quoteMap) {
    let cardStated = []
    Object.keys(groupKeys).forEach(key => {
        const columnKeys = groupKeys[key]
        columnKeys.forEach(key => {
            const cards = quoteMap[key]
            const allCardsStarted = cards.filter(card => card.todo?.started ?? false)
            cardStated.push(...allCardsStarted)
        })
    })

    return cardStated
}

export function getCardStarted(groupKeys, quoteMap) {
    let cardStated = null
    Object.keys(groupKeys).forEach(key => {
        const columnKeys = groupKeys[key]
        columnKeys.forEach(key => {
            const cards = quoteMap[key]
            if (cardStated) return
            cardStated = cards.find(card => card.todo?.started ?? false)
        })
    })

    return cardStated
}

export function getCardByCardKey(groupKeys, quoteMap, cardKey) {
    let c = null
    Object.keys(groupKeys).forEach(key => {
        const columnKeys = groupKeys[key]
        columnKeys.forEach(key => {
            const cards = quoteMap[key]
            if (c) return
            const index = cards.findIndex(card => card.cardKey === cardKey)
            if (index >= 0) {
                c = cards[index]
                c.columnKey = key
                c.indexQuote = index
            }
        })
    })

    return c
}

export function getColumnByStatus(groupKeys, columns, workPlanStatusType) {
    let c = null
    Object.keys(groupKeys).forEach(key => {
        const columnKeys = groupKeys[key]
        columnKeys.forEach(key => {
            const column = columns[key]
            if (c) return
            const status = column.workPlanStatus.find(w => w.type === workPlanStatusType)
            if (status) {
                c = key
            }
        })
    })

    return c
}

export function getIcon(cardType) {
    const icon = cardType === 'task' ? <Task16Icon /> :
        cardType === 'subtask' ? <Subtask16Icon /> :
            cardType === 'bug' ? <Bug16Icon /> :
                cardType === 'history' ? <Story16Icon /> :
                    cardType === 'impediment' ? <Changes16Icon /> :
                        cardType === 'epic' ? < Epic16Icon /> : <Calendar16Icon />
    return icon
}

export function getIconTitle(cardType) {
    const contentTooltip = cardType === 'task' ? 'Tarefa' :
        cardType === 'subtask' ? 'Sub tarefa' :
            cardType === 'bug' ? 'Bug' :
                cardType === 'history' ? 'História' :
                    cardType === 'impediment' ? 'Desvio' :
                        cardType === 'epic' ? 'Épico' : 'Evento calendário'
    return contentTooltip
}

export function getIconType(cardType) {
    const icon = cardType === 'task' ? <><Task16Icon /> Tarefa</> :
        cardType === 'subtask' ? <><Subtask16Icon /> Sub tarefa</> :
            cardType === 'bug' ? <><Bug16Icon /> Bug</> :
                cardType === 'history' ? <><Story16Icon /> História</> :
                    cardType === 'impediment' ? <><Changes16Icon /> Desvio</> :
                        cardType === 'epic' ? <><Epic16Icon /> Épico</> :
                            <><Calendar16Icon /> Evento calendário</>
    return icon
}