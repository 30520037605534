import Axios from "axios"

import * as person from './person'
import * as company from './cliente'
import * as project from './project'
import * as card from './card'
import * as workPlan from './workplan'
import * as notification from './notification'
import * as sprint from './sprint'
import * as activity from './activity'
import * as sector from './sector'
import * as costcenter from './costcenter'
import * as costcentergroup from './costcenterGroup'
import * as spotlight from './spotlight'
import * as document from './documents'
import * as account from './company'
import * as integration from './integration'
import * as management from './menagemente'
import * as report from './report'
import * as wiki from './wiki'
import * as calendario from './calendario'
import * as lobby from './lobby'
import { history } from "../history"

let _onError = null

const onError = (reject, resp) => {
    try {
        const message = resp.response.data?.Message ?? resp.response.data ?? 'Falha ao executar ação, tente novamente.'
        if (_onError) _onError(message)
        reject(message)
        if (message.code === 401 && message.message === "not authorized") {
            history.push('/pages/login')
        }
    } catch (error) {
        const message = 'Falha ao executar ação, tente novamente.'
        if (_onError) _onError(message)
        reject(message)
    }

}

export const get = (url) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${url}`)
            .then(resp => {
                resolve(resp)
            })
            .catch(resp => onError(reject, resp))
    })
}
export const post = (url, data) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${url}`, data)
            .then(resp => {
                resolve(resp)
            })
            .catch(resp => onError(reject, resp))
    })
}
export const put = (url, data) => {
    return new Promise((resolve, reject) => {
        Axios.put(`${url}`, data)
            .then(resp => {
                resolve(resp)
            })
            .catch(resp => onError(reject, resp))
    })
}
export const del = (url) => {
    return new Promise((resolve, reject) => {
        Axios.delete(`${url}`)
            .then(resp => {
                resolve(resp)
            })
            .catch(resp => onError(reject, resp))
    })
}

export const Querys = {
    Person: person,
    Company: company,
    Project: project,
    Card: card,
    WorkPlan: workPlan,
    Notification: notification,
    Sprint: sprint,
    Activity: activity,
    Sector: sector,
    CostCenter: costcenter,
    CostCenterGroup: costcentergroup,
    Spotlight: spotlight,
    Document: document,
    Account: account,
    Integration: integration,
    Management: management,
    Report: report,
    Wiki: wiki,
    Calendario: calendario,
    Lobby: lobby,
    OnError: (onError) => {
        _onError = onError
        return Querys
    }
}
