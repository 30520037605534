import axios from "axios"
import { Querys } from "../../../database/base"
import Config from "../../../Config"
import { Cache } from "../../../views/pages/cache/cache"

const INITIAL_STATE = {
    droppable: {},
    droppableOrder: [],
    id2List: {},
    kanban: []
}

const load = (state, action) => {
    var obj = {}
    var id2List = {}
    if (action.kanban)
        action.kanban.forEach(item => {
            (item.cards ?? []).forEach(x => {
                x.version = 0
            })
            obj[`droppable_${item.id}`] = item.cards ?? []
            id2List[`droppable_${item.id}`] = `droppable_${item.id}`
            item.cards = null
        })
    obj['droppable_delete'] = []
    id2List['droppable_delete'] = 'droppable_delete'
    setTimeout(() => {
        action.onLoaded()
    }, 10000);
    return {
        ...state,
        droppable: { ...obj },
        id2List: { ...id2List },
        droppableOrder: action.kanban?.map(item => `droppable_${item.id}`) ?? [],
        kanban: action?.kanban ?? []
    }
}

const findCard = (state, workPlanStatusType, cardKey, full) => {
    for (let index = 0; index < state.kanban.length; index++) {
        const ka = state.kanban[index];
        for (let index = 0; index < ka.workPlanStatus.length; index++) {
            const w = ka.workPlanStatus[index];
            if (w.type === workPlanStatusType || full) {
                for (let index = 0; index < state.droppable[`droppable_${ka.id}`].length; index++) {
                    const card = state.droppable[`droppable_${ka.id}`][index];
                    if (card.cardKey === cardKey) {
                        return { card, droppable: `droppable_${ka.id}`, index }
                    }
                }
            }
        }
    }
    if (full) {
        return null
    }
    return findCard(state, workPlanStatusType, cardKey, true)
}

const findCardByTodoID = (state, workPlanStatusType, todoID, full) => {
    for (let index = 0; index < state.kanban.length; index++) {
        const ka = state.kanban[index];
        for (let index = 0; index < ka.workPlanStatus.length; index++) {
            const w = ka.workPlanStatus[index];
            if (w.type === workPlanStatusType || full) {
                for (let index = 0; index < state.droppable[`droppable_${ka.id}`].length; index++) {
                    const card = state.droppable[`droppable_${ka.id}`][index];
                    if (card.todoID === todoID) {
                        return { card, droppable: `droppable_${ka.id}`, index }
                    }
                }
            }
        }
    }
    if (full) {
        return null
    }
    return findCardByTodoID(state, workPlanStatusType, todoID, true)
}

const findDroppable = (state, workPlanStatusType) => {
    for (let index = 0; index < state.kanban.length; index++) {
        const ka = state.kanban[index];
        for (let index = 0; index < ka.workPlanStatus.length; index++) {
            const w = ka.workPlanStatus[index];
            if (w.type === workPlanStatusType) {
                return `droppable_${ka.id}`
            }
        }
    }
}

const stopAllCards = (state) => {
    for (let index = 0; index < state.kanban.length; index++) {
        const ka = state.kanban[index];
        for (let index = 0; index < state.droppable[`droppable_${ka.id}`].length; index++) {
            const card = state.droppable[`droppable_${ka.id}`][index];
            if (card.todo && card.todo.started === true) {
                card.todo.started = false
                card.version++
            }
        }
    }
}

const create = (state, action) => {
    const { card } = action.message
    const { workPlanStatusType } = card
    const droppable = findDroppable(state, workPlanStatusType)
    if (state.droppable[droppable])
        state.droppable[droppable].push({ ...card, version: 0 })
    return {
        ...state
    }
}

// const findIndexOnDroppable = (state, workPlanStatusType, cardViewIndex) => {
//     let index = 0
//     state.droppable[findDroppable(state, workPlanStatusType)].forEach((card, idx) => {
//         if (card.cardViews[0].index > cardViewIndex && card.cardViews[0].index > index)
//             index = idx
//     })
//     return index
// }

const update = (state, action) => {
    const { card, cardKey } = action.message
    const { workPlanStatusType } = card
    const cardFinded = findCard(state, workPlanStatusType, cardKey)
    if (cardFinded !== null) {
        const { droppable, index } = cardFinded
        const oldCard = cardFinded.card
        if (card.todo && card.todo.started) {
            const user = Cache.user().get()
            if (user.codigo === card.todo.personCode)
                stopAllCards(state)
            else
                card.todo.started = false
        }
        card.version = oldCard.version + 1
        if (oldCard.workPlanStatusType !== workPlanStatusType) {
            state.droppable[droppable].splice(index, 1)
            const keyDroppeble = findDroppable(state, workPlanStatusType)
            if (state.droppable[keyDroppeble])
                state.droppable[keyDroppeble].splice(card.cardViews[0].index - 1, 0, card)
        } else {
            state.droppable[droppable][index] = card
        }
    }
    return { ...state }
}

const onChangeBoard = (state, action) => {
    Object.keys(action.message).forEach(key => {
        state.droppable[key] = action.message[key]
    })
    return { ...state }
}

const onUpdateField = (state, action) => {
    const cardFounded = findCard(state, "", action.id, true)
    if (cardFounded) {
        let { card, droppable, index } = cardFounded
        card = { ...card, ...action.message }
        card.version++
        state.droppable[droppable][index] = card
    }
    return { ...state }
}

const onStartActivity = (state, action) => {
    if (window.appOnline) {
        axios.post(`${Config.RouterUrl}/api/tracking/ping`)
    }
    let { cardKey, todoID } = action
    let find = findCard
    if (todoID) {
        find = findCardByTodoID
        cardKey = todoID
    }
    const cardFounded = find(state, "", cardKey, true)
    if (cardFounded) {
        stopAllCards(state)
        let { card, droppable, index } = cardFounded
        card.todo = { ...card.todo, started: true }
        card.version++
        state.droppable[droppable][index] = card
    }
    return { ...state }
}

const onStopActivity = (state, action) => {
    let { cardKey, todoID } = action
    let find = findCard
    if (todoID) {
        find = findCardByTodoID
        cardKey = todoID
    }
    const cardFounded = find(state, "", cardKey, true)
    if (cardFounded) {
        let { card, droppable, index } = cardFounded
        card.todo = { ...card.todo, started: false }
        card.version++
        state.droppable[droppable][index] = card
    }
    return { ...state }
}

const onDeleteCard = (state, action) => {
    const { cardKey } = action
    const cardFounded = findCard(state, "", cardKey, true)
    if (cardFounded) {
        let { droppable, index } = cardFounded
        state.droppable[droppable].splice(index, 1)
    }
    return { ...state }
}

const onTrackingEvents = (state, action) => {
    if (action.data.event === "EVENT_ACTIVITY_STOP") {
        const { data } = action.data
        const cardFounded = findCardByTodoID(state, "", data.CodigoTarefa)
        if (cardFounded) {
            let { card, droppable, index } = cardFounded
            card.todo = { ...card.todo, time: data.Tempo, originalTime: null, startingDate: null }
            card.version++
            state.droppable[droppable][index] = card
        }
        return { ...state }
    }
    if (action.data.event === "EVENT_ACTIVITY_START") {
        const { data } = action.data
        const cardFounded = findCardByTodoID(state, "", data.CodigoTarefa)
        if (cardFounded) {
            let { card, droppable, index } = cardFounded
            card.todo = { ...card.todo, time: data.Tempo, originalTime: data.Tempo, startingDate: data.Tempos[action.data.data.Tempos.length - 1].data_inicio }
            card.version++
            state.droppable[droppable][index] = card
        }
        return { ...state }
    }
    if (action.data.event === 'EVENT_ACTIVITY_INSERT') {
        const { data } = action.data
        const cardFounded = findCardByTodoID(state, "", data.CodigoTarefa)
        if (cardFounded) {
            let { card, droppable, index } = cardFounded
            card.todo = { ...card.todo, time: data.Tempo, originalTime: data.Tempo, startingDate: data.Tempos[action.data.data.Tempos.length - 1].data_inicio }
            card.version++
            state.droppable[droppable][index] = card
        }
        return { ...state }
    }
    return { ...state }
}

export const kanban = (state = INITIAL_STATE, action) => {
    try {
        window.onEventEvent(action)
        switch (action.type) {
            case "TRACKING_EVENTS": return onTrackingEvents(state, action)
            // eventos
            case "LOAD": return load(state, action)
            case "CREATE": return create(state, action)
            case "UPDATE": return update(state, action)
            case "ACTIVITY_CREATE": return update(state, action)
            case "ACTIVITY_STARTED": {
                window.activeCardKey = action.message.cardKey
                return update(state, action)
            }
            case "ACTIVITY_STOPED": return update(state, action)
            case "CHANGE_BOARD": return update(state, action)

            // tela
            case "ON_CHAGE_BOARD": return onChangeBoard(state, action)
            case "ON_UPDATE_FIELD": return onUpdateField(state, action)
            case "ON_START_ACTIVITY": return onStartActivity(state, action)
            case "ON_STOP_ACTIVITY": return onStopActivity(state, action)
            case "ON_DELETE_CARD": return onDeleteCard(state, action)
            default: {
                return state
            }
        }
    } catch (error) {
        console.log("kanbanErr:", error)
        return state
    }
}
