import React, { Component } from 'react'
import Avatar from '@atlaskit/avatar';
import url from '../Config'
import moment from 'moment'
import Tooltip from '@atlaskit/tooltip';

const avatarToken = localStorage.getItem('avatarToken')

class AvatarPessoa extends Component {
    render() {
        if (this.props.noTooltip) {
            return <Avatar
                {...this.props}
                size={this.props.size === 'lg' ? 'xxlarge' : this.props.size === 'md' ? 'medium' : 'small'}
                appearance='circle'
                presence={this.props.presence ?? undefined}
                name={this.props.nome}
                src={`${url.RouterUrl}/api/back/pessoa/${this.props.codigo}/foto?${moment(avatarToken).format('YYYYMMDDHHmmss')}`}
            />
        }
        
        return (
            <Tooltip content={this.props.nome}>
                <Avatar
                    {...this.props}
                    size={this.props.size === 'lg' ? 'xxlarge' : this.props.size === 'md' ? 'medium' : 'small'}
                    appearance='circle'
                    name={this.props.nome}
                    presence={this.props.presence ?? undefined}
                    src={`${url.RouterUrl}/api/back/pessoa/${this.props.codigo}/foto?${moment(avatarToken).format('YYYYMMDDHHmmss')}`}
                />
            </Tooltip>
        )
    }
}
export default AvatarPessoa
