import { del, get, post, put } from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/agenda`
}

export const OauthCalendarioList = (id) => {
    return get(`${getUrl()}/oauth/${id}/calendarList`)
}

export const ImportCalendarOauth = (id, data) => {
    return post(`${getUrl()}/calendar/${id}/importOauth`, data)
}

export const CalendarList = () => {
    return get(`${getUrl()}/calendar`)
}

export const CalendarShared = () => {
    return get(`${getUrl()}/calendar/shared`)
}

export const CalendarShareWithPerson = (calendar, action) => {
    return put(`${getUrl()}/calendar/${calendar}/${action}/share`)
}

export const EventCardsOutOffCalendar = () => {
    return get(`${getUrl()}/event/cards`)
}

export const EventByExternalID = (externalID) => {
    return get(`${getUrl()}/event/${externalID}/external`)
}

export const CreateEventCard = (data) => {
    return post(`${getUrl()}/event/card`, data)
}

export const UpdateProject = (id, data) => {
    return put(`${getUrl()}/event/${id}/project`, data)
}

export const CalendarEvents = (date) => {
    return post(`${getUrl()}/event`, { date })
}

export const CalendarUpdate = (id, data) => {
    return put(`${getUrl()}/calendar/${id}`, data)
}

export const CalendarUpdateShared = (id, data) => {
    return put(`${getUrl()}/calendar/shared/${id}`, data)
}

export const CreateEvent = (date) => {
    return post(`${getUrl()}/calendar/event`, date)
}

export const UpdateEvent = (date, id) => {
    return put(`${getUrl()}/event/${id}`, date)
}

export const DeleteEvent = (id) => {
    return del(`${getUrl()}/event/${id}`)
}

export const CalendarDeleteShared = (id) => {
    return del(`${getUrl()}/calendar/shared/${id}`)
}

export const CalendarDelete = (id) => {
    return del(`${getUrl()}/calendar/${id}`)
}

export const ChangeStartEvent = (id, data) => {
    return put(`${getUrl()}/event/${id}/changeStart`, data)
}

export const CalendarUpdateVisibility = (id, data) => {
    return put(`${getUrl()}/calendar/${id}/visibility`, data)
}
