import React, { Component } from 'react'
import Button from '@atlaskit/button';
import ShareIcon from '@atlaskit/icon/glyph/share'
import Tooltip from '@atlaskit/tooltip';
import InlineDialog from '@atlaskit/inline-dialog';
import { Querys } from '../../../../../database/base';
import Textfield from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search'
import AvatarPessoa from '../../../../../utility/AvatarPessoa';
import CheckBoxesVuexy from '../../../../../components/@vuexy/checkbox/CheckboxesVuexy';
import * as Icon from "react-feather"
import { Modal, ModalBody } from 'reactstrap';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right'
import Task16Icon from '@atlaskit/icon-object/glyph/task/16'
import Subtask16Icon from '@atlaskit/icon-object/glyph/subtask/16'
import Bug16Icon from '@atlaskit/icon-object/glyph/bug/16'
import Story16Icon from '@atlaskit/icon-object/glyph/story/16'
import Epic16Icon from '@atlaskit/icon-object/glyph/epic/16'
import Changes16Icon from '@atlaskit/icon-object/glyph/changes/16'
import { getIcon } from '../../../painel/v2/helper';

export default class SetParent extends Component {
    state = {
        search: '',
        data: []
    }
    render() {
        return (
            <Modal
                isOpen={this.props.open}
                toggle={this.props.onToogle}
                className="modal-dialog-centered">
                <ModalBody>
                    <div className='w-100'>
                        <Textfield
                            autoFocus={true}
                            placeholder='filtrar por chave ou descrição do card'
                            className='input-search-dropdown-people w-100'
                            value={this.state.search}
                            onChange={e => {
                                if (e.target.value.length >= 3) {
                                    Querys.Card.Filter(e.target.value, this.props.project)
                                        .then(({ data }) => this.setState({ data }))
                                }
                                this.setState({ search: e.target.value })
                            }}
                            elemBeforeInput={
                                <div className='search-icon'>
                                    <SearchIcon size='small'></SearchIcon>
                                </div>
                            } />
                        {this.state.data.length === 0 &&
                            <small className='p-1'>
                                Nenhum Card filtrado / encontrado
                            </small>}
                        {this.state.data.length > 0 &&
                            <>
                                <div className='p-1 w-100'>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <b>Chave</b>
                                        Nome
                                    </div>
                                    {this.state.data
                                        .map((item, index) => {
                                            const { cardType } = item
                                            const icon = getIcon(cardType)
                                            return (
                                                <div key={`filter-itens-${index}`}
                                                    className='d-flex justify-content-between items-filter-parent'
                                                    onClick={() => {
                                                        let cardType = ''
                                                        if (this.props.cardType !== 'epic' && this.props.cardType !== 'history') {
                                                            cardType = 'history'
                                                        } else if (this.props.cardType === 'history') {
                                                            cardType = 'epic'
                                                        }
                                                        Querys.Card.UpdateFields(this.props.parent, { parentCardID: item.id })
                                                        Querys.Card.UpdateFields(item.id, { cardType: cardType })
                                                        this.props.onToogle()
                                                    }}>
                                                    <div>
                                                        {icon}{" "}
                                                        <b>{item.cardKey}</b>
                                                    </div>
                                                    {item.name}
                                                </div>
                                            )
                                        })}

                                </div>
                            </>}

                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
