import React from 'react'
import Modal, { ModalBody, ModalTransition, ModalHeader, ModalTitle, ModalFooter } from '@atlaskit/modal-dialog';

function ModalSuricatoo({ open, testId, width, height, onClose, autoFocus, header, appearance, children, footer }) {
    return (
        <ModalTransition>
            {open &&
                <Modal testId={testId} width={width} height={height} onClose={onClose} shouldScrollInViewport={true}
                    autoFocus={autoFocus ?? true}>
                    {header &&
                        <ModalHeader>
                            <ModalTitle appearance={appearance ?? ''}>
                                {header}
                            </ModalTitle>
                        </ModalHeader>}
                    <ModalBody>
                        {children}
                    </ModalBody>
                    {footer &&
                        <ModalFooter>
                            {footer}
                        </ModalFooter>}
                </Modal>}
        </ModalTransition>
    )
}
export default React.memo(ModalSuricatoo)