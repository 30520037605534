import { del, get, post, put } from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/lobby`
}

export const GoIn = (lobby) => {
    return post(`${getUrl()}/${lobby}/goIn`)
}

export const GoOut = (lobby) => {
    return post(`${getUrl()}/${lobby}/goOut`)
}

export const Alive = (lobby) => {
    return post(`${getUrl()}/${lobby}/alive`)
}

export const Invite = (lobby, personCode) => {
    return post(`${getUrl()}/${lobby}/${personCode}/invite`)
}

export const Message = (lobby, data) => {
    return post(`${getUrl()}/${lobby}/message`, data)
}

export const Get = (lobby) => {
    return get(`${getUrl()}/${lobby}`)
}

export const Restart = (lobby) => {
    return post(`${getUrl()}/${lobby}/restart`)
}

export const Finish = (lobby) => {
    return post(`${getUrl()}/${lobby}/finish`)
}
